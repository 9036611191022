import styled from "@emotion/styled";
import React from "react";

interface SectionTitleProps {
  title: string;
}
export default function SectionTitle(props: SectionTitleProps) {
  return (
    <SectionTitleWrap>
      {props.title}
    </SectionTitleWrap>
  );
}

const SectionTitleWrap = styled.h3`
  //font-family: Didot;
  font-weight: normal;
  font-size: 40px;
  letter-spacing: 0.1em;
  line-height: 93px;
  text-align: center;
  color: #707070;
`;

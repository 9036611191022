import styled from "@emotion/styled";
import { navigate } from "gatsby-link";
import React, { useState } from "react";
import SectionTitle from "../components/common/SectionTitle";
import Layout from "../components/layout";

type FormData = {
  name: string;
  mailAddress: string;
  inquiry: string;
};

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

interface IContactForm {
  name: string;
  email: string;
  message: string;
}

export default function ContactForm() {
  const [state, setState] = useState<IContactForm>({
    name: "",
    email: "",
    message: "",
  });

  // const handleChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...state,
      }),
    })
      .then(() => navigate("/thanks"))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <ContactFormWrap>
        <SectionTitle title="Contact" />
        <p className="contact_greeting">
          お問い合わせは下記のフォームよりお願いします
        </p>
        <Form
          name="contact"
          method="post"
          action="/thanks"
          data-netlify="true"
          netlify-honeypot="bot-field"
          // onSubmit={handleSubmit}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <div className="form_label">
            お名前
            <div className="form_item">
              <input
                type="text"
                name="name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setState({ ...state, name: e.target.value })
                }
                placeholder="ヤマダ タロウ"
              />
            </div>
          </div>

          <div className="form_label">
            メールアドレス
            <div className="form_item">
              <input
                type="email"
                name="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setState({ ...state, email: e.target.value })
                }
                placeholder="mail@example.com"
              />
            </div>
          </div>

          <div className="form_label">
            お問い合わせ内容
            <div className="form_item">
              <textarea
                name="message"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setState({ ...state, message: e.target.value })
                }
                placeholder="入力してください"
              />
            </div>
          </div>

          <ButtonWrapper>
            <button type="submit">送信</button>
          </ButtonWrapper>
        </Form>
      </ContactFormWrap>
    </Layout>
  );
}

const ContactFormWrap = styled.div`
  background-color: black;
  color: #fff;
  max-width: 528px;
  margin: 0 auto;
  padding: 100px 24px;
  width: 100%;

  .contact_greeting {
    text-align: center;
    //font-family: YuMincho;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 26px;

    color: #fff;
  }

  input {
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;

  margin: 30px 0;

  .form_label {
    //font-family: YuMincho;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 26px;
    text-align: left;
    color: #fff;
  }

  .form_item {
    display: flex;
    flex-direction: column;
    row-gap: 7px;

    input {
      width: 100%;
      height: 40px;
      color: #606060;
      background-color: #a0a0a0;
      padding: 0 12px;

      &:focus {
        border: 3px solid #fff;
        padding: 0 9px;
      }
    }

    textarea {
      width: 100%;
      height: 140px;
      color: #606060;
      background-color: #a0a0a0;
      padding: 12px 12px;

      &:focus {
        border: 3px solid #fff;
        padding: 9px 9px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;

  button {
    margin: 0 auto;
    width: 182px;
    height: 50px;
    background-color: white;
    cursor: pointer;

    //font-family: YuMincho;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 26px;
    text-align: center;
    color: #000;
  }
`;
